// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UntitledModel, Faq } = initSchema(schema);

export {
  UntitledModel,
  Faq
};