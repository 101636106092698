import React from 'react';
import './App.scss';
import { TestItemCollection} from "./ui-components";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {Button, Link} from "@aws-amplify/ui-react";

function App() {
    return (
        <Container className="App" >
           
            <img height="350px" src="/logo.png" alt={"logo"}/>
            
            <Alert variant={"success"}>
                <p>
                    Timeline Billing is currently invitation only.
                </p>
                <p>
                    Please check back soon!
                </p>
            </Alert>
            <Row className={"Header"}>
                
                <Col className={"my-2"} >
                    <Link href={"https://portal.timelinebilling.com"}>
                        <Button>login
                        </Button>
                    </Link>
                </Col>
                
            </Row>
            <div className={"Main"}>
                Frequently Asked Questions: 
                <Container>
                <Row>
                    <Col>
                <Row>
                    <Col>
                <TestItemCollection/>
                    </Col>
                </Row>
                    </Col>
                </Row>
                </Container>
            </div>
        </Container>
    );
}

export default App;
